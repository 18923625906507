.collection-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
  .info-column {
    width: 60%;
    padding: 16px;
    .media-object {
      display: flex;
      align-items: center;
    }
    .media {
      margin-right: 16px;
      h3 {
        font-size: 32px;
        background-color: $primary-red;
        color: $white;
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 22px;
        i {
          font-size: 32px;
        }
      }
    }
    .object {
      .amount {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 2px;
        color: $gray-dark;
      }
      .meta {
        font-size: 14px;
        color: $gray-light;
        word-break: break-word;
      }
    }
  }
  .progress-column {
    width: 40%;
    padding: 16px 8px;
  }
}

.progress-bar-wrapper {
  .progress-bar {
    width: 100%;
    max-width: 300px;
    height: 16px;
    border-radius: 8px;
    background-color: $gray-dark-bg;
    position: relative;
    .progressed {
      position: absolute;
      top: 0;
      left: 0;
      height: 16px;
      background: $primary-green;
      border-radius: 8px;
    }
  }
  .progress-status {
    margin-top: 8px;
    color: $gray-light;
  }
}

.collection-payments {
  .collection-payments-item:last-child {
    border-bottom: none;
  }
}
.collection-payments-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid lighten($color: $gray-eighty, $amount: 15);
  .info-column {
    width: 65%;
    padding: 8px 8px 8px 0;
    .media-object {
      display: flex;
      align-items: center;
    }
    .media {
      margin-right: 16px;
      h3 {
        font-size: 20px;
        background-color: $primary-red;
        color: $white;
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 22px;
      }
    }
    .object {
      .amount {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 2px;
        color: $gray-dark;
      }
      .meta {
        font-size: 13px;
        color: $gray-light;
        word-break: break-word;
      }
    }
  }
  .status-column {
    width: 35%;
    padding: 8px 0 8px 0px;
    text-align: right;
    .payment-status {
      display: inline-block;
      margin-bottom: 4px;
    }
    .mode {
      font-size: 13px;
      color: $gray-light;
    }
  }
}

.auth-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $gray-light-bg;
  min-height: 100vh;
  .wrapper {
    min-width: 320px;
    text-align: center;
    padding: 32px;
    border-radius: 16px;
    background: $white;
  }
  @media (max-width: 480px) {
    background: $white;
    .wrapper {
      background: transparent;
    }
  }
}
.auth-brand {
  margin-bottom: 36px;
  img {
    max-width: 174px;
  }
}
.auth-button {
  height: 48px;
  margin: 24px 0;
}

.welcome-banner {
  display: block;
  max-width: 300px;
  width: 100%;
  margin: 0 auto;
}

.welcome-message {
  margin-top: 16px;
  margin-bottom: 24px;
  font-size: 17px;
  color: $gray-medium;
}

.auth-message {
  padding: 8px;
  background-color: lighten($color: $red, $amount: 38);
  border: 1px solid $red;
  color: $gray-dark;
  margin-bottom: 16px;
  border-radius: 8px;
}

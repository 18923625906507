.intro {
  margin-bottom: 24px;
  h1 {
    font-weight: 400;
    word-break: break-word;
  }
}

.sub-title {
  margin-bottom: 8px;
  color: $gray-medium;
}
.stats {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 24px;
  .stats-item {
    flex-basis: calc(50%);
    ul {
      li {
        background: $white;
        border-radius: 16px;
        padding: 16px;
        box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
        margin-right: 16px;
        h2 {
          margin-bottom: 2px;
          font-size: 28px;
        }
        p {
          color: $gray-light;
          font-size: 14px;
        }
      }
    }
    &:last-child {
      ul {
        li {
          margin-right: 0;
        }
      }
    }
  }
}

.no-pending-payments {
  padding: 24px;
  text-align: center;
  background: lighten($primary-green, 50);
  color: $primary-green;
  border-radius: 16px;
}

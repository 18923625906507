.residents-line-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  flex-wrap: wrap;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
  .info-column {
    width: 40%;
    padding: 16px;
    .media-object {
      display: flex;
      align-items: center;
    }
    .media {
      margin-right: 16px;
      h3 {
        font-size: 20px;
        background-color: $primary-red;
        color: $white;
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 22px;
      }
    }
    .object {
      .amount {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 2px;
        color: $gray-dark;
      }
      .meta {
        font-size: 14px;
        color: $gray-light;
        span {
          display: none;
          @media (max-width: 480px) {
            display: inline-block;
            margin-top: 4px;
          }
        }
      }
    }
    @media (max-width: 480px) {
      width: 100%;
    }
  }
}
.detail-page {
  .resident-info {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.resident-info {
  margin-bottom: 24px;
  h2 {
    margin-bottom: 16px;
    color: $gray-light;
    font-weight: normal;
  }
  h3 {
    margin-bottom: 8px;
    // color: $gray-light;
  }
  p {
    color: $gray-light;
    word-break: break-all;
    i {
      margin-right: 4px;
    }
  }
  ul {
    li {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
      p {
        margin-bottom: 4px;
      }
    }
  }
}

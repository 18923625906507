.ReactModal__Overlay {
  z-index: 25;
  -webkit-perspective: 600;
  perspective: 600;
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 103;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.ReactModal__Content {
  -webkit-transform: scale(0.5) rotateX(-30deg);
  transform: scale(0.5) rotateX(-30deg);
}

.ReactModal__Content--after-open {
  -webkit-transform: scale(1) rotateX(0deg);
  transform: scale(1) rotateX(0deg);
  transition: all 150ms ease-in;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content--before-close {
  -webkit-transform: scale(0.5) rotateX(30deg);
  transform: scale(0.5) rotateX(30deg);
  transition: all 150ms ease-in;
}

.ReactModal__Content.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.modal-wrapper {
  flex: 1;
  overflow: hidden;
  padding: 16px;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slide-right {
  &.ReactModal__Content {
    scale: initial;
    animation-duration: 300ms;
    animation-timing-function: ease-in;
  }
  &.ReactModal__Content--after-open {
    animation-name: slideInRight;
  }
  &.ReactModal__Content--before-close {
    animation-name: slideOutRight;
  }
}

.modal-content {
  background-color: $white;
  border-radius: 16px;
  margin: 0 auto;
  max-width: 300px;
  .modal-header {
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      font-size: 18px;
      line-height: 32px;
    }
    .modal-close {
      background-color: transparent;
      padding: 0;
      height: 32px;
      width: 32px;
      i {
        font-size: 24px;
        color: #999;
      }
    }
  }
  .modal-body {
    padding: 8px 24px;
    color: $gray-medium;
    line-height: 32px;
  }
  .modal-footer {
    padding: 24px;
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 16px;
      min-width: 90px;
    }
  }
}

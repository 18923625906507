.pagination {
  padding: 24px;
  ul {
    display: flex;
    justify-content: center;
    li {
      button {
        min-width: 44px;
        height: 44px;
        padding: 0;
        border-radius: 16px;
        margin: 0 4px;
        background: $white;
        border: 1px solid $primary-red;
        color: $primary-red;
        &.active {
          background: $primary-red;
          border: none;
          color: $white;
        }
        &:disabled {
          opacity: initial;
        }
      }
    }
    @media (max-width: 480px) {
      flex-wrap: wrap;
      li {
        button {
          margin-bottom: 4px;
        }
      }
    }
  }
}

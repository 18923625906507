.form-group {
  margin-bottom: 16px;
  .invalid-feedback {
    color: $red;
    font-size: 14px;
    margin-top: 8px;
  }
}

.form-label {
  margin-bottom: 8px;
  display: block;
  color: $gray-dark;
}

.form-control {
  display: block;
  border-radius: 16px;
  height: 48px;
  padding: 0 16px;
  width: 100%;
  border: 1px solid $gray-eighty;
  &:active,
  &:focus {
    border-color: $primary-red;
    outline: none;
  }
  &.is-invalid {
    border-color: $red;
  }
}

textarea {
  &.form-control {
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: 80px;
  }
}

.field-group {
  display: flex;
  justify-content: space-between;
  .form-group {
    flex-basis: 48%;
  }
}

.react-select__control {
  min-height: 48px !important;
  min-width: 100px;
  height: auto;
  border-radius: 16px !important;
  &.react-select__control--is-focused {
    min-height: 48px;
    border-radius: 16px;
    border-width: 1px;
    box-shadow: none;
    border-color: $primary-red !important;
  }
}

.field-array-wrapper {
  padding: 16px;
  background: $white;
  margin-bottom: 24px;
  border-radius: 16px;
  border: 1px solid $gray-eighty;
  .field-array-label {
    margin-bottom: 16px;
  }
}

.field-array-item {
  display: flex;
  // justify-content: space-between;
  .form-group {
    width: 150px;
    margin-right: 16px;
  }
}

.date-picker-wrapper{
  .react-date-picker__wrapper {
    border: none;
  }
  .react-date-picker__clear-button {
    background: transparent;
    color: #333;
  }
  .react-calendar {
    border-radius: 16px;
    border: 1px solid #ccc;
    font-family: inherit;
    button {
      color: #333;
      padding: 4px;
      height: 40px;
      &.react-calendar__tile--active {
        color: #fff;
      }
    }
  }
}
$primary-red: #821a17;
$primary-orange: #fc7710;
$primary-green: #1da922;
$white: #fff;
$gray-eighty: #ccc;
$gray-light: #999;
$gray-medium: #666;
$gray-dark: #333;
$black: #222;
$red: #ff5722;
$gray-light-bg: #f5f5f5;
$gray-medium-bg: #f1f1f1;
$gray-dark-bg: #dddddd;
$green-light-bg: rgba(147, 220, 156, 0.15);

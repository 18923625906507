.flat-fields {
  display: flex;
  align-items: flex-start;
  //justify-content: space-between;
  flex-wrap: wrap;
}

.flat-fields-item {
  background: $white;
  border-radius: 16px;
  padding: 8px;
  flex-basis: calc(25% - 24px);
  margin-bottom: 24px;
  margin-left: 12px;
  margin-right: 12px;
  position: relative;
  .form-group {
    margin-bottom: 0;
    padding: 16px;
  }
  .form-control {
    border: none;
    background: transparent;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    margin: 0;
    padding: 0;
    &:active,
    &:active,
    &:hover {
      background: $gray-light-bg;
    }
  }
  .remove-item {
    position: absolute;
    top: -12px;
    right: -12px;
    font-size: 24px;
    height: 24px;
    width: 24px;
    line-height: 22px;
    padding: 0;
    border: 1px solid $red;
    background: $white;
    color: $red;
  }
  &.add-item {
    button {
      background: transparent;
      color: $primary-red;
      min-height: 80px;
      width: 100%;
      i {
        font-size: 32px;
        display: block;
        margin-bottom: 6px;
      }
    }
  }
}
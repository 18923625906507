.payments-list {
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  .status-label {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    text-transform: uppercase;
    &.pending {
      background: rgb(240, 240, 47);
    }
    &.credit {
      background: rgb(47, 240, 160);
    }
    &.error,
    &.failed {
      background: rgb(240, 118, 47);
    }
  }
  @media (max-width: 480px) {
    flex-direction: column;
    li {
      margin-bottom: 8px;
    }
  }
}

.add-payment {
  margin-bottom: 24px;
}

.payment-response {
  text-align: center;
  .result {
    text-align: center;
    padding: 16px;
    background: #fff;
    border-radius: 8px;
    margin: 0 auto 24px auto;
    max-width: 360px;
    img {
      display: block;
      width: 100%;
      max-width: 280px;
      margin: 0 auto 24px auto;
      opacity: 0.85;
    }
    h1 {
      margin-bottom: 24px;
    }
    code {
      color: $primary-red;
    }
  }
}

.collection-item {
  background: $white;
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
}

.collection-line-item {
  margin-top: 16px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    background-color: #f7f7f7;
    padding: 8px 16px;
    border-radius: 16px;
    p {
      flex-basis: 33.33%;
      font-size: 14px;
      &.flat-no {
        font-weight: bold;
        text-align: center;
        max-width: 36px;
        height: 36px;
        line-height: 36px;
        background-color: $primary-red;
        border-radius: 18px;
        padding: 0;
        color: $white;
        font-size: 16px;
      }
    }
  }
}

.payment-status {
  padding: 4px 8px;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 12px;
  font-size: 13px;
  color: $white;
  &.open {
    background: #327ab7;
    border-color: #327ab7;
  }
  &.pending {
    background: #f0ad4e;
    border-color: #f0ad4e;
  }
  &.paid {
    background: #5cb85c;
    border-color: #5cb85c;
    color: $white;
  }
  &.overdue {
    background: #ff4d8f;
    border-color: #ff4d8f;
  }
  &.failed {
    background: #d9534f;
    border-color: #d9534f;
  }
  &.write-off {
    background: #661717;
    border-color: #661717;
  }
}

.account-info {
  text-align: left;
  background-color: lighten($primary-orange, 45);
  border-radius: 16px;
  padding: 16px;
  color: $primary-orange;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 32px 0;
  h3 {
    line-height: 24px;
    margin: 0 0 16px 0 !important;
  }
  .account-info-icon {
    height: 48px;
    width: 48px;
    margin-right: 16px;
  }
  // i {
  //   font-size: 32px;
  //   line-height: 32px;
  //   margin-right: 8px;
  // }
  .account-info-details {
    flex: 1;
  }
  .account-info-number {
    display: flex;
    justify-content: space-between;
    //align-items: center;
    & > div {
      flex: 1;
    }
    p {
      margin-bottom: 8px;
    }
    button {
      height: 32px;
      padding: 0 16px;
      font-size: 14px;
      background: #1da922;
      margin-bottom: 0;
      i {
        font-size: 14px;
        margin: 0 0 0 8px;
      }
    }
  }
}

.payment-paid-message {
  // text-align: center;
  background-color: lighten($primary-green, 55);
  border-radius: 16px;
  padding: 16px;
  color: $primary-green;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  i {
    font-size: 32px;
    line-height: 32px;
    margin-right: 8px;
  }
}

.payment-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  flex-wrap: wrap;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
  .info-column {
    width: 40%;
    padding: 16px;
    .media-object {
      display: flex;
      align-items: center;
    }
    .media {
      margin-right: 16px;
      h3 {
        font-size: 20px;
        background-color: $primary-red;
        color: $white;
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 22px;
      }
    }
    .object {
      .amount {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 2px;
        color: $gray-dark;
      }
      .meta {
        font-size: 14px;
        color: $gray-light;
        span {
          display: none;
          @media (max-width: 480px) {
            display: inline-block;
            margin-top: 4px;
          }
        }
      }
    }
    @media (max-width: 480px) {
      width: 70%;
    }
  }
  .paid-by-column {
    width: 25%;
    padding: 16px 8px;
    @media (max-width: 480px) {
      display: none;
    }
  }
  .date-column {
    width: 20%;
    padding: 16px 8px;
    @media (max-width: 480px) {
      display: none;
    }
  }
  .status-column {
    width: 15%;
    padding: 16px 8px;
    float: right;
    @media (max-width: 480px) {
      width: 30%;
      text-align: right;
      padding: 16px;
    }
  }
}

.upi-payment-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(226, 226, 226, 0.2) !important;
  background-color: #fff !important;
  background: #fff;
  padding: 8px 16px;
  margin-bottom: 16px;
  border-radius: 8px;
  img,
  .icon-receipt_long {
    width: 32px;
    height: 32px;
    margin-right: 24px;
  }
  .icon-receipt_long {
    font-size: 32px;
    line-height: 32px;
    opacity: 0.65;
  }
  .icon-navigate-next {
    margin-left: auto;
    font-size: 32px;
    // color: #ccc;
    opacity: 0.33;
  }
}

.detail-page-actions {
  display: block;
  h3 {
    margin: 32px 0 16px 0;
  }
}

.verify-receipt {
  padding: 32px;
  .form-control {
    margin: 16px 0;
  }
  .progress-bar {
    display: block;
    width: 100%;
  }
  .verify-result {
    margin-top: 16px;
    background-color: #d6f2d6;
    padding: 16px;
    border-radius: 8px;
    color: #024702;
  }
}

.container {
  max-width: 900px;
  margin: 0 auto;
}

.main-container {
  padding: 16px 0;
  margin-top: 70px;
  min-height: calc(100vh - 70px);
  @media (max-width: 960px) {
    padding: 16px;
    margin-top: 59px;
    min-height: calc(100vh - 59px);
  }
}

.large-lazy-loader {
  min-height: 100vh;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.3;
}

.loader-wrapper {
  padding: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-message {
  text-align: center;
  padding: calc(100vh - 532px) 24px;
  border-radius: 16px;
  margin: 16px 0;
  background: $white;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid $primary-red;
  width: 64px;
  height: 64px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.common-layout {
  .side-menu-wrapper {
    background: $gray-dark;
    width: 250px;
    height: 100vh;
  }
  .main-wrapper {
    width: 100%;
    overflow: hidden;
    .main-container {
      transform: translateX(0);
      transition: transform 0.5s cubic-bezier(0.07, 0.23, 0.34, 1);
    }
    .backdrop {
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.5s cubic-bezier(0.07, 0.23, 0.34, 1);
    }
    &.side-menu-open {
      position: relative;
      .main-container {
        transform: translateX(250px);
      }
      .backdrop {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.dropdown {
  // .dropdown-toggle {
  //   color: red;
  // }
  position: relative;
  .dropdown-menu {
    display: none;
    position: absolute;
    top: 30px;
    right: 0;
    background: $white;
    border-radius: 8px;
    padding: 16px;
    width: 200px;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.3);
  }
  &.show {
    .dropdown-menu {
      display: block;
    }
  }
}

// Common Page Header
.page-header {
  margin: 8px 0 16px 0;
  color: #999;
  display: flex;
  align-items: center;
  .back-button {
    padding: 0;
    width: 44px;
    background: transparent;
    color: $primary-red;
    i {
      font-size: 24px;
    }
  }
  h3 {
    font-size: 18px;
    font-weight: normal;
    color: $primary-red;
  }
  .action-buttons {
    margin-left: auto;
    button {
      margin-left: 16px;
    }
  }
}

.detail-page {
  padding: 24px;
  border-radius: 16px;
  margin: 16px 0;
  background: #fff;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  min-height: calc(100vh - 200px);
  .primary-info {
    margin-bottom: 32px;
    h1 {
      margin-bottom: 4px;
      font-size: 48px;
    }
    p {
      color: $gray-medium;
    }
  }
  .meta-info {
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        flex-basis: calc(25% - 16px);
        margin-bottom: 16px;
        padding-right: 16px;
        @media (max-width: 480px) {
          flex-basis: 50%;
          &:nth-child(even) {
            padding-right: 0;
          }
        }
        p {
          color: $gray-light;
          font-size: 13px;
          margin-bottom: 4px;
          &.progress-status {
            color: $gray-medium;
          }
        }
        h3 {
          font-size: 16px;
          word-break: break-word;
          .payment-status {
            font-size: 14px;
            font-weight: normal;
          }
        }
      }
    }
  }
  @media (max-width: 480px) {
    padding: 16px;
  }
}

.detail-line-item {
  display: flex;
  margin-bottom: 16px;
  i {
    margin-right: 16px;
    font-size: 32px;
    opacity: 0.5;
  }
}

.detail-page-actions {
  display: flex;
  justify-content: space-between;
  button {
    flex-basis: 30%;
  }
  @media (max-width: 480px) {
    flex-direction: column;
    button {
      margin-bottom: 16px;
      flex-basis: auto;
    }
  }
}

.error-message {
  text-align: center;
  padding: 48px 24px;
  border-radius: 16px;
  margin: 16px 0;
  background: #fff;
  // max-width: 400px;
  // margin: 120px auto;
  color: $gray-medium;
  // box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
  img {
    display: block;
    max-width: 180px;
    margin: 0 auto 32px auto;
    opacity: 0.75;
  }
  h2 {
    font-size: 48px;
    line-height: 48px;
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 32px;
  }
  button {
    min-width: 120px;
  }
}

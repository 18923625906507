header {
  background: $primary-red;
  padding: 16px 0;
  box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.033);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  transform: translateX(0);
  transition: transform 0.5s cubic-bezier(0.07, 0.23, 0.34, 1);
  &.side-menu-open {
    transform: translateX(250px);
  }
  .container {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 960px) {
    padding: 16px;
  }
  @media (max-width: 480px) {
    padding: 8px;
  }
}
@keyframes slideUp {
  0% {
    transform: translateY(30px);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.menu-button {
  background: transparent;
  color: $white;
  padding: 0 8px;
  // flex-basis: 33.33%;
  i {
    font-size: 24px;
  }
}
.my-account {
  // flex-basis: 33.33%;
  .dropdown {
    .dropdown-menu {
      width: 300px;
    }
    .dropdown-toggle {
      color: $white;
      i {
        font-size: 24px;
        vertical-align: middle;
        color: $white;
      }
      span {
        margin-left: 8px;
        @media (max-width: 480px) {
          display: none;
        }
      }
    }
    .dropdown-item {
      display: block;
      padding: 8px 0;
      word-break: break-all;
      &:last-child {
        border-bottom: none;
      }
      &.disabled {
        color: $gray-light;
        span {
          font-size: 13px;
        }
      }
    }
  }
}
// Logo
.brand {
  // flex-basis: 33.33%;
  .logo {
    width: 128px;
    @media (max-width: 480px) {
      width: 110px;
    }
  }
}

// navbar
.navbar {
  margin-left: auto;
  a {
    margin-left: 16px;
    color: $white;
    &.active {
      font-weight: 700;
    }
  }
}

.side-menu-wrapper {
  max-height: 100vh;
  width: 250px;
  position: fixed;
  top: 0;
  z-index: 101;
  transform: translateX(-250px);
  transition: transform 0.5s cubic-bezier(0.07, 0.23, 0.34, 1);
  &.open {
    transform: translateX(0);
  }
  ul {
    li {
      a {
        display: block;
        padding: 16px;
        color: $gray-light;
        i {
          font-size: 24px;
          margin-right: 16px;
          vertical-align: middle;
          color: lighten($gray-light, 5);
        }
        &.active {
          background: darken($primary-red, 5);
          color: $white;
          font-weight: bold;
        }
      }
    }
  }
}

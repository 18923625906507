@font-face {
  font-family: "apartment-icons";
  src: url("../../fonts/nest-iris/fonts/apartment-icons.ttf") format("truetype"),
    url("../../fonts/nest-iris/fonts/apartment-icons.woff?qqcwk0")
      format("woff"),
    url("../../fonts/nest-iris/fonts/apartment-icons.svg?qqcwk0#apartment-icons")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "apartment-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-sentiment_satisfied_alt:before {
  content: "\e913";
}
.icon-collections_bookmark:before {
  content: "\e914";
}
.icon-group:before {
  content: "\e915";
}
.icon-assignment:before {
  content: "\e91f";
}
.icon-shopping_basket:before {
  content: "\e925";
}
.icon-logout:before {
  content: "\e92a";
}
.icon-menu_open:before {
  content: "\e930";
}
.icon-speed:before {
  content: "\e931";
}
.icon-badge:before {
  content: "\e934";
}
.icon-receipt_long:before {
  content: "\e935";
}
.icon-verified:before {
  content: "\e936";
}
.icon-housekeeping:before {
  content: "\e937";
}
.icon-water:before {
  content: "\e938";
}
.icon-electricity:before {
  content: "\e939";
}
.icon-painting:before {
  content: "\e93a";
}
.icon-plumbing:before {
  content: "\e93b";
}
.icon-others:before {
  content: "\e93c";
}
.icon-error:before {
  content: "\e900";
}
.icon-warning:before {
  content: "\e901";
}
.icon-phone:before {
  content: "\e902";
}
.icon-location:before {
  content: "\e903";
}
.icon-chat-bubble:before {
  content: "\e904";
}
.icon-add-circle-outline:before {
  content: "\e905";
}
.icon-clear:before {
  content: "\e906";
}
.icon-create:before {
  content: "\e907";
}
.icon-email:before {
  content: "\e908";
}
.icon-remove-circle-outline:before {
  content: "\e909";
}
.icon-report:before {
  content: "\e90a";
}
.icon-access-time:before {
  content: "\e90b";
}
.icon-format_list_bulleted:before {
  content: "\e933";
}
.icon-insert-drive-file:before {
  content: "\e90c";
}
.icon-keyboard-arrow-up:before {
  content: "\e90d";
}
.icon-navigate-before:before {
  content: "\e90e";
}
.icon-navigate-next:before {
  content: "\e90f";
}
.icon-radio-button-unchecked:before {
  content: "\e910";
}
.icon-view:before {
  content: "\e911";
}
.icon-category:before {
  content: "\e912";
}
.icon-arrow-back:before {
  content: "\e916";
}
.icon-arrow-forward:before {
  content: "\e917";
}
.icon-check:before {
  content: "\e921";
}
.icon-menu:before {
  content: "\e918";
}
.icon-more-vertical:before {
  content: "\e919";
}
.icon-user:before {
  content: "\e91a";
}
.icon-share:before {
  content: "\e91b";
}
.icon-check-box:before {
  content: "\e91c";
}
.icon-check-box-outline_blank:before {
  content: "\e91d";
}
.icon-radio-button-checked:before {
  content: "\e91e";
}
.icon-star:before {
  content: "\e928";
}
.icon-star-half:before {
  content: "\e92b";
}
.icon-star-outline:before {
  content: "\e92c";
}
.icon-check-circle:before {
  content: "\e92d";
}
.icon-delete:before {
  content: "\e920";
}
.icon-favorite:before {
  content: "\e92e";
}
.icon-remove:before {
  content: "\e922";
}
.icon-info:before {
  content: "\e923";
}
.icon-search:before {
  content: "\e924";
}
.icon-settings:before {
  content: "\e92f";
}
.icon-today:before {
  content: "\e926";
}
.icon-hide:before {
  content: "\e927";
}
.icon-check-circle-outline:before {
  content: "\e929";
}
.icon-house:before {
  content: "\e932";
}
.icon-spinner:before {
  content: "\e982";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-youtube:before {
  content: "\ea9d";
}

.expense-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $white;
  border-radius: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.06);
  .info-column {
    width: 60%;
    padding: 16px;
    .media-object {
      display: flex;
      align-items: center;
    }
    .media {
      margin-right: 16px;
      h3 {
        font-size: 32px;
        background-color: $primary-red;
        color: $white;
        width: 44px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        border-radius: 22px;
        i {
          font-size: 32px;
        }
      }
    }
    .object {
      .amount {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 2px;
        color: $gray-dark;
      }
      .meta {
        font-size: 14px;
        color: $gray-light;
      }
    }
  }
  .payee-column {
    padding: 16px;
    width: 20%;
    @media (max-width: 480px) {
      display: none;
    }
  }
  .date-column {
    // font-size: 14px;
    text-align: right;
    padding: 16px;
    width: 20%;
    @media (max-width: 480px) {
      width: 40%;
    }
  }
}

.expense {
  .meta-info {
    ul {
      li {
        flex-basis: 100% !important;
      }
    }
  }
}
